// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';
import { useSiteMetadata } from '../hooks';


export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        tags
        title
        socialImage {
          publicURL
        }
      }
    }
  }
`;

const PostTemplate = ({ data }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  // Extracting data from the GraphQL query
  const post = data.markdownRemark;
  const {
    html,
    fields: { slug, tagSlugs },
    frontmatter: {
      date,
      description,
      tags,
      title,
      socialImage
    },
  } = post;

  const metaDescription = description || siteSubtitle;
  const socialImageUrl = socialImage?.publicURL;

  return (

    <Layout
      title={`${title} - ${siteTitle}`}
      description={metaDescription}
      socialImage={socialImageUrl}
    >
      <Post html={html}
        tagSlugs={tagSlugs}
        slug={slug}
        tags={tags}
        title={title}
        date={date}
      />
    </Layout>
  );
};

export default PostTemplate;